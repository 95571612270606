export const cfg = {
  env: import.meta.env.dev || import.meta.env.NODE_ENV || 'production',
  ftApiUri: import.meta.env.https://api.dev.fantasythrone.com/graphql,
  ftWsUri: import.meta.env.wss://api.dev.fantasythrone.com/subscriptions,
  ftMockApiUri: import.meta.env.https://api.dev.fantasythrone.com,
  awsmobile: {
    aws_project_region: import.meta.env.us-east-1,
    aws_cognito_identity_pool_id: import.meta.env.us-east-1_o3LpG1vw3,
    aws_cognito_region: import.meta.env.us-east-1,
    aws_user_pools_id: import.meta.env.us-east-1_o3LpG1vw3,
    aws_user_pools_web_client_id: import.meta.env.ile5ghoip4pa76st8dgeb0koj,
  },
  paysafeKey: import.meta.env.VITE_PAYSAFE_PUBLIC_ENCODED_KEY,
  geoComplyApiKey: import.meta.env.VITE_GEOCOMPLY_API_KEY,
  geoComplySecretKey: import.meta.env.VITE_GEOCOMPLY_SECRET_KEY,
};
export default cfg;
