import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Container, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Text from '../../../components/Common/Text';
import LeagueRosterPlayerListItem from '../../../components/League/LeagueRoster/LeagueRosterPlayerListItem/LeagueRosterPlayerListItem';
import { useAppContext } from '../../../context/AppProvider';

import s from './LeagueMatchup.module.scss';

export const LeagueMatchup = ({ league, lineup, oppLineup, oppUserId, positions }) => {
  const [{ user }] = useAppContext();
  const userId = user.attributes.sub;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const myTeam = league.franchises.find((franchise) => franchise.userId === userId);
  const myScore = league?.schedule?.matchup[0]?.fantasyPoints;
  const myPlace = league.contest.results?.overall?.find((result) => result?.franchise?.userId === userId)?.place;

  const opponentTeam = league.franchises.find((franchise) => franchise.userId === oppUserId);
  const opponentScore = league?.schedule?.matchup[1]?.fantasyPoints;
  const opponentPlace = league.contest.results?.overall?.find((result) => result?.franchise?.userId === oppUserId)?.place;

  const userMatchup = league?.schedule?.matchup?.find((matchup) => matchup.userId === userId);
  const currentWeek = userMatchup?.week ?? null;

  if (!currentWeek) {
    return (
      <Container disableGutters className={s.noMatchup}>
        <Text align='center' variant='body1'>
          {league?.isComplete ? 'This league has finished.' : 'You do not have a matchup scheduled for this week.'}
        </Text>
      </Container>
    );
  }
  const renderColumnHeader = () => (
    <Grid container>
      <Grid container item xs={4} md={6}/>
      <Grid container item xs={8} md={6} justifyContent='space-between'>
        <Grid container item justifyContent='center' xs={4}>
          <Text bold display='block' variant={desktop ? 'body2' : 'caption'}>
            OPRK
          </Text>
        </Grid>
        <Grid container justifyContent='center' item xs={4}>
          <Text bold display='block' variant={desktop ? 'body2' : 'caption'}>
            PROJ
          </Text>
        </Grid>
        <Grid container justifyContent='center' item xs={4}>
          <Text bold display='block' variant={desktop ? 'body2' : 'caption'}>
            SCORE
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTeamHeader = (team, score, place) => (
    <Grid container xs={12} justifyContent='center' className={s.header}>
      <Grid item xs={2} align='center' className={s.avatarContainer}>
        <Avatar src={team?.franchiseLogo} />
      </Grid>
      <Grid container item xs={3}>
        <Grid container item justifyContent='center' xs={12}>
          <Text variant={desktop ? 'body1' : 'subtitle2'}>
            {team?.franchiseName}
          </Text>
        </Grid>
        <Grid container item justifyContent='center' xs={12}>
          <Text variant={desktop ? 'body2' : 'subtitle2'}>
            ({team?.record.wins}-{team?.record.losses})
          </Text>
        </Grid>
      </Grid>
      {/* <Grid item xs={1} align='center' alignContent='center'>
        <Text variant={desktop ? 'body2' : 'subtitle1'}>{place ?? 'T-1st'}</Text>
      </Grid> */}
      <Grid container item xs={2} align='center' alignContent='center'>
        <Text bold variant={desktop ? 'body1' : 'subtitle2'}>
          {score > 0 ? score?.toFixed(2) : '0.00'}
        </Text>
      </Grid>
    </Grid>
  );

  const gridTemplateRows = desktop ? `108px 24px repeat(${lineup.length}, 96px)` : `200px 24px repeat(${lineup.length}, 120px)`;

  return (
    <Grid container xs={12} className={s.root}>
      <Grid container item xs={6} alignItems='center' direction='column' style={{ gridTemplateRows, display: 'grid' }}>
        {renderTeamHeader(myTeam, myScore, myPlace)}
        {renderColumnHeader()}
        {lineup.map((slot, i) => (
          <LeagueRosterPlayerListItem desktop={desktop} idx={i} draftId={league?.draftId} key={i} player={slot} rosterSlot={positions[i]} />
        ))}
      </Grid>
      <Grid container item xs={6} alignItems='center' direction='column' className={s.column} style={{ gridTemplateRows, display: 'grid' }}>
        {renderTeamHeader(opponentTeam, opponentScore, opponentPlace)}
        {renderColumnHeader()}
        {oppLineup.map((slot, i) => (
          <LeagueRosterPlayerListItem desktop={desktop} idx={i} draftId={league?.draftId} key={i} player={slot} rosterSlot={positions[i]} />
        ))}
      </Grid>
    </Grid>
  );
};

LeagueMatchup.propTypes = {
  league: PropTypes.shape({
    franchises: PropTypes.arrayOf(PropTypes.shape({
      userId: PropTypes.string,
      franchiseLogo: PropTypes.string,
      franchiseName: PropTypes.string,
      record: PropTypes.shape({
        wins: PropTypes.number,
        losses: PropTypes.number,
      }),
    })),
    schedule: PropTypes.shape({
      matchup: PropTypes.arrayOf(PropTypes.shape({
        fantasyPoints: PropTypes.number,
        userId: PropTypes.string,
        week: PropTypes.number,
      })),
    }),
    contest: PropTypes.shape({
      results: PropTypes.shape({
        overall: PropTypes.arrayOf(PropTypes.shape({
          franchise: PropTypes.shape({
            userId: PropTypes.string,
          }),
          place: PropTypes.string,
        })),
      }),
    }),
    isComplete: PropTypes.bool,
  }).isRequired,
  lineup: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  oppLineup: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  oppUserId: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LeagueMatchup;
